import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/layouts/default-layout.js";
import ScaleBuilder from 'applets/scalebuilder';
import ShowHideContent from 'components/showhidecontent';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CommonPitch = makeShortcode("CommonPitch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "scale-builder-for-indian-musical-scales",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#scale-builder-for-indian-musical-scales",
        "aria-label": "scale builder for indian musical scales permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Scale Builder for Indian musical scales`}</h2>
    <p>{`Scale Builder is a tool to build microtonally accurate scales for Ragas in Indian Classical music. For an introduction to Scale Builder and a quick user guide, click `}<inlineCode parentName="p">{`Show`}</inlineCode>{`. You can use the common parameters below to set the `}<inlineCode parentName="p">{`Sa`}</inlineCode>{` to any pitch you prefer.`}</p>
    <ShowHideContent title='Guide to Scale Builder' mdxType="ShowHideContent">
      <h3 {...{
        "id": "introduction",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#introduction",
          "aria-label": "introduction permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Introduction`}</h3>
      <p>{`Indian Ragas and their underlying scales are based on the principles of consonance and symmetry. This means that the interval between any two notes in a Raga can be described:`}</p>
      <ul>
        <li parentName="ul">{`Using the common intervals of `}<em parentName="li">{`Pancham`}</em>{` (fifth), `}<em parentName="li">{`Madhyam`}</em>{` (fourth) and `}<em parentName="li">{`Gandhar`}</em>{` (major third), or`}</li>
        <li parentName="ul">{`By means of symmetrical intervals in a different part of the octave in that Raga.`}</li>
      </ul>
      <p>{`By describing musical intervals in this manner, you can use Scale Builder to build microtonally accurate scales for Indian Ragas. Scale Builder uses `}<em parentName="p">{`Linear Algebra`}</em>{` to solve for a scale that meets all the `}<em parentName="p">{`constraints`}</em>{` imposed by the musical intervals you specify.`}</p>
      <h3 {...{
        "id": "syntax",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#syntax",
          "aria-label": "syntax permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Syntax`}</h3>
      <p>{`Scale Builder requires each relevant musical interval to be specified as a constraint in a separate line, based on the following syntax.`}</p>
      <ul>
        <li parentName="ul">{`Individual notes are denoted as `}<inlineCode parentName="li">{`Sa re Re ga Ga ma Ma Pa dha Dha ni Ni`}</inlineCode>{` where notes beginning with a lower case letter are `}<em parentName="li">{`komal`}</em>{` or flat notes, and notes beginning with an upper case letter are `}<em parentName="li">{`tivra`}</em>{` or sharp notes.`}</li>
        <li parentName="ul">{`There are three `}<em parentName="li">{`Saptaks`}</em>{` (octaves):  `}<em parentName="li">{`Mandra`}</em>{` (low), `}<em parentName="li">{`Madhya`}</em>{` (middle) and `}<em parentName="li">{`Tara`}</em>{` (high). A `}<inlineCode parentName="li">{`"`}</inlineCode>{` symbol denotes `}<em parentName="li">{`Tara Saptak`}</em>{`, a `}<inlineCode parentName="li">{`'`}</inlineCode>{` symbol denotes `}<em parentName="li">{`Mandra Saptak`}</em>{` and no octave specification means `}<em parentName="li">{`Madhya Saptak`}</em>{`.`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`(Sa,Pa)`}</inlineCode>{` stands for the interval from Sa to Pa.`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`(Sa,Pa)`}</inlineCode>{` is the same as `}<inlineCode parentName="li">{`(Pa,Sa)`}</inlineCode>{`, but different from `}<inlineCode parentName="li">{`(Pa,Sa")`}</inlineCode>{`.`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`I(P)`}</inlineCode>{`, `}<inlineCode parentName="li">{`I(m)`}</inlineCode>{` and `}<inlineCode parentName="li">{`I(G)`}</inlineCode>{` are respectively the `}<em parentName="li">{`Pancham`}</em>{`, `}<em parentName="li">{`Madhyam`}</em>{` and `}<em parentName="li">{`Gandhar`}</em>{` intervals.`}</li>
        <li parentName="ul">{`So `}<inlineCode parentName="li">{`(Sa,Pa) = I(P)`}</inlineCode>{` means that `}<inlineCode parentName="li">{`Sa`}</inlineCode>{` and `}<inlineCode parentName="li">{`Pa`}</inlineCode>{` have a `}<em parentName="li">{`Pancham`}</em>{` relationship (which need not always be the case).`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`(Pa,Dha) = (ma,Pa)`}</inlineCode>{` means that the `}<inlineCode parentName="li">{`Pa-Dha`}</inlineCode>{` interval and `}<inlineCode parentName="li">{`ma-Pa`}</inlineCode>{` interval are the same.`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`(Re)`}</inlineCode>{` is a shortcut for `}<inlineCode parentName="li">{`(Sa,Re)`}</inlineCode>{`.`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`S(note)`}</inlineCode>{` means that the `}<inlineCode parentName="li">{`note`}</inlineCode>{` should use the `}<em parentName="li">{`standard`}</em>{` value from the Venkatamakhin-Ramamatya tuning system. For example, `}<inlineCode parentName="li">{`(ga) = S(ga)`}</inlineCode>{` means that `}<inlineCode parentName="li">{`ga`}</inlineCode>{` should use the standard value.`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`D(note)`}</inlineCode>{` means that the `}<inlineCode parentName="li">{`note`}</inlineCode>{` should be deleted from the scale (although it may be used to derive another note in the scale). For example, `}<inlineCode parentName="li">{`D(Pa)`}</inlineCode>{` means that `}<inlineCode parentName="li">{`Pa`}</inlineCode>{` is not be used in the scale.`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`T(note)`}</inlineCode>{` means that the generated scale should be transposed with the `}<inlineCode parentName="li">{`note`}</inlineCode>{` taken as the tonic note. For example, `}<inlineCode parentName="li">{`T(ma)`}</inlineCode>{` means that the generated scale should be transposed to `}<inlineCode parentName="li">{`ma`}</inlineCode>{`.`}</li>
      </ul>
      <h3 {...{
        "id": "examples",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#examples",
          "aria-label": "examples permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Examples`}</h3>
      <p>{`You can use the following examples to try out Scale Builder and also to familiarize yourself with the constraint syntax.`}</p>
      <h4 {...{
        "id": "sama-gana-scale",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h4" {...{
          "href": "#sama-gana-scale",
          "aria-label": "sama gana scale permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Sama Gana Scale`}</h4>
      <p>{`The `}<a parentName="p" {...{
          "href": "/learn/tuningsystems-1/"
        }}>{`Sama Gana scale`}</a>{` starts with the idea that `}<inlineCode parentName="p">{`ma`}</inlineCode>{` and `}<inlineCode parentName="p">{`Pa`}</inlineCode>{` have a perfect `}<em parentName="p">{`Madhyam`}</em>{` and `}<em parentName="p">{`Pancham`}</em>{` relationship with `}<inlineCode parentName="p">{`Sa`}</inlineCode>{`. Then using the `}<inlineCode parentName="p">{`(ma,Pa)`}</inlineCode>{` interval, `}<inlineCode parentName="p">{`Re`}</inlineCode>{` and `}<inlineCode parentName="p">{`ni`}</inlineCode>{` are derived by going up and down from `}<inlineCode parentName="p">{`Sa`}</inlineCode>{` by an amount equal to `}<inlineCode parentName="p">{`(ma,Pa)`}</inlineCode>{`. Similarly, `}<inlineCode parentName="p">{`ga`}</inlineCode>{` is derived by going down from `}<inlineCode parentName="p">{`ma`}</inlineCode>{` and `}<inlineCode parentName="p">{`Dha`}</inlineCode>{` by going up from `}<inlineCode parentName="p">{`Pa`}</inlineCode>{`, by the same amount `}<inlineCode parentName="p">{`(ma,Pa)`}</inlineCode>{`. This leads to the following constraints.`}</p>
      <pre><code parentName="pre" {...{}}>{`(Sa,Pa) = I(P)
(Sa,ma) = I(m)
(Sa,Re) = (ma,Pa)
(ga,ma) = (ma,Pa)
(Pa,Dha) = (ma,Pa)
(ni,Sa") = (ma,Pa)
`}</code></pre>
      <h4 {...{
        "id": "shadaj-gram-scale",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h4" {...{
          "href": "#shadaj-gram-scale",
          "aria-label": "shadaj gram scale permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Shadaj Gram Scale`}</h4>
      <p>{`The `}<a parentName="p" {...{
          "href": "/learn/tuningsystems-2/"
        }}>{`Shadaj Gram scale`}</a>{` starts with the Sama Gana scale, but shifts `}<inlineCode parentName="p">{`Re`}</inlineCode>{` and `}<inlineCode parentName="p">{`Dha`}</inlineCode>{` down in pitch. `}<inlineCode parentName="p">{`Dha`}</inlineCode>{` is shifted down in pitch so that `}<inlineCode parentName="p">{`(ma,Dha)`}</inlineCode>{` is a perfect `}<em parentName="p">{`Gandhar`}</em>{`. Since, `}<inlineCode parentName="p">{`Re`}</inlineCode>{` and `}<inlineCode parentName="p">{`Dha`}</inlineCode>{` are maintained to have a perfect `}<em parentName="p">{`Pancham`}</em>{` relationship, we need to specify that `}<inlineCode parentName="p">{`(Re,Dha) = I(P)`}</inlineCode>{`. This leads to the following constraints.`}</p>
      <pre><code parentName="pre" {...{}}>{`(Sa,Pa) = I(P)
(Sa,ma) = I(m)
(ga,ma) = (ma,Pa)
(ni,Sa") = (ma,Pa)
(ma,Dha) = I(G)
(Re,Dha) = I(P)
`}</code></pre>
      <h3 {...{
        "id": "results",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#results",
          "aria-label": "results permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Results`}</h3>
      <p>{`You can build the scale by clicking on `}<inlineCode parentName="p">{`Build`}</inlineCode>{` after specifying all the musical intervals of significance in the Raga. In case, Scale Builder is unable to build the scale, it would provide you with a list of notes which cannot be determined. You can add or change constraints till you get a solution.`}</p>
      <p>{`On successfully building a scale, Scale Builder would provide a keyboard to listen to the scale so that you check if it matches with your expectations. You can also read further information generated by Scale Builder including its tuning with respect to the Venkatamakhin-Ramamatya scale and the symmetric intervals in the scale. These symmetric intervals should be inline with your expectations about the `}<em parentName="p">{`Lakshanas`}</em>{` of the Raga.`}</p>
    </ShowHideContent>
    <p></p>
    <CommonPitch mdxType="CommonPitch" />
    <ScaleBuilder mdxType="ScaleBuilder" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      